import { createContext, useContext, useEffect, useState } from "react"

const ThemeContext = createContext({theme : "light", changeTheme: (theme : string) => {}})

export const useThemeContext = () => {
    return useContext(ThemeContext)
}

export const ThemeContextProvider = ({ children }) => {
    const [theme, setTheme] = useState("light")

    useEffect(() => {
        if(!localStorage.theme){
            changeTheme("light")
        }else{
            changeTheme(localStorage.theme)
        }
    }, [])

    const changeTheme = (theme: string) => {
        localStorage.theme = theme
        if (
            localStorage.theme === "dark" ||
            (!("theme" in localStorage) &&
                window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
            document.documentElement.classList.add("dark")
        } else {
            document.documentElement.classList.remove("dark")
        }
        setTheme(theme)
    }

    return (
        <ThemeContext.Provider value={{ theme, changeTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}
