import { useState } from "react"
import Header from "../containers/Header"
import Footer from "../containers/Footer"
import Drawer from "../containers/Drawer"
import { Outlet } from "react-router-dom"

const PageLayout = () => {
    const [showDrawer, setShowDrawer] = useState(false)

    return (
        <>
            <div className="w-full dark:bg-dark dark:bg-icon-bg-dark bg-transition">
                {/* <div className="flex flex-col-reverse lg:flex-col items-center">
                    <Header onMenuClick={() => setShowDrawer(true)} />
                </div> */}
                <Outlet />
                {/* <Footer /> */}
            </div>
        </>
    )
}

export default PageLayout
