import { ReactNode } from "react"
import { twMerge } from "tailwind-merge"

type textVariants = "primary" | "secondary" | "footer" | "sub" | "drawer" | "disabled" | "title" | "subtitle"

const variantToClass = {
    primary: "dark:text-white-text text-black-text",
    secondary: "text-dark-gray dark:text-light-gray ",
    footer: "text-black-text dark:text-light-black",
    sub: "text-dark-gray dark:text-light-gray hover:text-primary dark:hover:text-primary cursor-pointer",
    drawer: "text-drawer-light-text dark:text-drawer-dark-text",
    disabled: "light-gray dark:text-dark-gray",
    title: "dark:text-white-text text-black-text text-2xl lg:text-title tracking-tighter font-semibold",
    subtitle: "text-xs lg:text-sm text-primary "
}

const Text = ({
    className,
    variant,
    children,
    onClick
}: {
    className?: string
    variant: textVariants
    children?: ReactNode
    onClick?: () => void
}) => {
    return (
        <div onClick={onClick} className={twMerge(` ${variantToClass[variant]} ${className}`)}>
            {children}
        </div>
    )
}

export default Text;
