import Features from "../containers/Features"
import Hero from "../containers/Hero"
import Litepaper from "../containers/Litepaper"
import Connect from "../containers/Connect"
import Community from "../containers/Community"
import Partnership from "../containers/Partnership"

const Home = () => {
    return (
        <>
            <Hero />
        </>
    )
}

export default Home
