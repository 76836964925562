import { DiscordIcon, MailIcon, TwitterIcon } from "../assets/Icon"
import ImageButton from "../components/ImageButton"
import Text from "../components/Text"
import useMediaQuery from "../hooks/useMediaQuery"
import ReactPlayer from "react-player"
import Button from "../components/Button"
import { openInNewTab } from "../utils/helpers"
import { links } from "../utils/constants"
import ImageDark from "../assets/images/hero-image.png"
import ImageLight from "../assets/images/hero-image-light.png"
import { useThemeContext } from "../hooks/ThemeContext"

const Hero = () => {
    const VIDEO_LINK =
        "https://gogocoin-assets.s3.eu-central-1.amazonaws.com/gogo-coin-buy-video.mp4"

    const isTablet = useMediaQuery("(min-width: 768px)")
    const { theme } = useThemeContext()

    const SocialButtons = () => {
        return (
            <div className="p-4 flex  gap-3 w-full h-full  md:px-0">

                {/* <ImageButton
                    icon={<GoogleIcon />}
                    className="flex-1"
                    text="Continue with Google"
                />
                <ImageButton
                    icon={<FacebookIcon />}
                    className="flex-1"
                    text="Continue with Google"
                /> */}
                {/* <div className="flex gap-3">
                    <ImageButton icon={<DiscordIcon />} className="flex-1" />
                    <ImageButton icon={<TwitterIcon />} className="flex-1" />
                    <ImageButton icon={<MailIcon />} className="flex-1" />
                </div> */}
            </div>
        )
    }

    return (
        <div className="px-24 py-48 text-center">
            
            <div className="text-2xl font-bold text-center">
                <Text variant="title">
                    Support for GOGO Protocol is  being phased out. The sunset announcement can be found <a className="blue" href="https://gogocoin-assets.s3.eu-central-1.amazonaws.com/sunset.pdf">here</a></Text>

                    
            </div>

            <div className="mt-8">
                <Text variant="sub" >
                    You can access the <a className="blue" href="https://app.gogocoin.io">legacy dapp</a> to unstake your positions. 
                </Text>
            </div>


        </div>
    )
}

export default Hero

