import { ThemeContextProvider } from "./hooks/ThemeContext"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import PageLayout from "./pages/PageLayout"
import Home from "./pages/Home"
import Disclaimer from "./pages/TermsAndConditions"
import { PrivacyDetails } from "./containers/PrivacyDetails"
import QuillhashAudit from "./pages/QuillhashAudit"
import Contact from "./pages/Contact"
import { useEffect } from "react"

declare global {
    interface Window {
        cookiehub: any
    }
}

const App = () => {
    // const loadCookiehub = () => {
    //     setTimeout(function () {
    //         if (!("cookiehub" in window)) {
    //             const script = document.createElement("script")
    //             script.src = "https://cookiehub.net/c2/04f303c0.js"
    //             script.onload = function () {
    //                 console.log("loaded")
    //                 window?.cookiehub.load({})
    //             }
    //             document.head.appendChild(script)
    //         }
    //     }, 1000)
    // }

    useEffect(() => {
        if (document.readyState === "complete") {
            // loadCookiehub()
        } else {
            // document.onreadystatechange = () => {
            //     if (document.readyState === "complete") {
            //         loadCookiehub()
            //     }
            // }
        }
    }, [])

    return (
        <ThemeContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<PageLayout />}>
                        <Route path="" element={<Home />} />
                        {/* <Route path="disclaimer" element={<Disclaimer />} />
                        <Route path="privacy" element={<PrivacyDetails />} />
                        <Route path="quillhash" element={<QuillhashAudit />} />
                        <Route path="contact" element={<Contact />} /> */}
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeContextProvider>
    )
}

export default App
